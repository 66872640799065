import React from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import LoadingSpinner from "../components/LoadingSpinner";
import ErrorMessage from "../components/ErrorMessage";
import NoBikesMessage from "../components/NoBikesMessage";
import useGetBikeById from "../components/hooks/useGetBikeById";
import useGetCompanyById from "../components/hooks/useGetCompanyById";
import { Pagination, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

const FeaturePill = ({ text }) => (
  <span className="bg-gray-200 text-gray-700 px-2 py-1 rounded-full text-xs">
    {text}
  </span>
);

const BikePage = () => {
  const { id } = useParams();
  const { bike, loading: bikeLoading, error: bikeError } = useGetBikeById(id);
  const {
    company,
    loading: companyLoading,
    error: companyError,
  } = useGetCompanyById(bike?.companyID);

  if (bikeLoading) {
    return <LoadingSpinner />;
  }

  if (bikeError) {
    return <ErrorMessage error={bikeError} />;
  }

  if (!bike) {
    return <NoBikesMessage />;
  }

  const formatPrice = (price) => {
    return price.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const renderCompanyInfo = () => {
    if (!bike.companyID) {
      return null;
    }

    if (companyLoading) {
      return <LoadingSpinner />;
    }

    if (companyError) {
      return <ErrorMessage error={companyError} />;
    }

    return <p className="text-[#757676] mr-5 mt-2">{company?.companyName}</p>;
  };

  return (
    <div className="min-h-screen py-2 bg-white">
      <div className="container max-w-7xl mx-auto px-8 sm:px-6 lg:px-8">
        <Helmet>
          <title>{bike.name}</title>
          <meta name="description" content={`View details for ${bike.name}.`} />
          <meta property="og:title" content={bike.name} />
          <meta
            property="og:description"
            content={`Check out the details for ${bike.name}.`}
          />
        </Helmet>
        <div className="bike-card rounded-lg py-4 relative flex flex-col">
          {bike.images && bike.images.length > 0 ? (
            <div className="relative w-full pb-[56.25%] overflow-hidden rounded-lg shadow-md">
              <Swiper
                modules={[Pagination, A11y]}
                spaceBetween={0}
                slidesPerView={1}
                pagination={{ clickable: true }}
                className="absolute top-0 left-0 w-full h-full"
              >
                {bike.images.map((image, index) => (
                  <SwiperSlide key={index} className="w-full h-full">
                    <div className="w-full h-full flex items-center justify-center bg-gray-100">
                      <img
                        src={image}
                        alt={`${bike.name} - Image ${index + 1}`}
                        className="w-full h-full object-contain"
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              <style>
                {`
                .swiper-pagination-bullet {
                  background: #FFF;
                  opacity: 0.5;
                }

                .swiper-pagination-bullet-active {
                  background: #FFF;
                  opacity: 1;
                }

                .swiper, .swiper-wrapper, .swiper-slide {
                  height: 100% !important;
                }
              `}
              </style>
            </div>
          ) : (
            <div className="w-full pb-[56.25%] bg-gray-200 rounded-lg flex items-center justify-center relative">
              <span className="absolute text-gray-500">No image available</span>
            </div>
          )}
          <div className="mt-4 flex flex-col justify-end">
            <p className="text-lg font-bold">
              {bike.name} {bike.displacement}cc {bike.year}
            </p>
            <div className="flex items-center mt-2">
              <p className="text-[#757676] mr-5">
                <strong>Rp{formatPrice(parseFloat(bike.dailyPrice))}</strong>{" "}
                day
              </p>
              <p className="text-[#757676] mr-5">
                <strong>Rp{formatPrice(parseFloat(bike.weeklyPrice))}</strong>{" "}
                week
              </p>
              <p className="text-[#757676] mr-5">
                <strong>Rp{formatPrice(parseFloat(bike.monthlyPrice))}</strong>{" "}
                month
              </p>
            </div>
            <div className="flex flex-wrap gap-1 mt-2">
              {bike.surfRack && <FeaturePill text="🤙 Surf Rack" />}
              {bike.keyless && <FeaturePill text="🔑 Keyless" />}
              {bike.ABS && <FeaturePill text="🛞 ABS" />}
            </div>

            {/*renderCompanyInfo()*/}

            <a
              href="https://flexbike.app/download"
              target="_blank"
              rel="noopener noreferrer"
              className="mt-4 inline-block font-bold border px-4 py-2 rounded-lg shadow-lg bg-[#08b7b7] whitespace-nowrap"
            >
              Book now on Flexbike
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BikePage;