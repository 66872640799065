import QRCode from "qrcode.react";
import { useEffect } from "react";
import { isAndroid, isIOS } from "react-device-detect";
import appstore from "../assets/appstore.svg";
import googleplay from "../assets/googleplay.svg";

const DownloadPage = () => {
  useEffect(() => {
    if (isAndroid) {
      window.location.href = "https://play.google.com/store/apps/details?id=com.flexbike&hl=en&gl=US";
    } else if (isIOS) {
      window.location.href = "https://apps.apple.com/us/app/flexbike/id6449488641";
    }
  }, []);

  return (
    <>
      <div style={{ backgroundColor: "#F6F5F1" }} className="h-screen">
        <div className="p-5">
          <div className="h-20 w-20">
            <a href="/">
              <img
                className="rounded-full p-3 object-cover"
                src={`${process.env.PUBLIC_URL}/logo512.png`}
                alt="Flexbike logo"
                style={{ backgroundColor: "#08b7b7" }}
              />
            </a>
          </div>
        </div>

        <div className="flex flex-col justify-center items-center">
          <p className="mt-8 mx-8 text-center text-4xl leading-12 font-bold font-nunito" style={{ color: "#242420" }}>
            Download
            <span> </span>
            <span style={{ backgroundColor: "#08b7b7" }} className="px-3">
              Flexbike
            </span>
          </p>

          {isAndroid && (
            <>
              <p className="mx-8 my-8 text-center text-xl leading-12 font-nunito" style={{ color: "#242420" }}>
                For Android users, tap below to download from the Play Store.
              </p>

              <a href="https://play.google.com/store/apps/details?id=com.flexbike&hl=en&gl=US" target="blank">
                <img src={googleplay} alt="Get Flexbike on Google Play" />
              </a>
            </>
          )}

          {isIOS && (
            <>
              <p className="mx-8 my-8 text-center text-xl leading-12 font-nunito" style={{ color: "#242420" }}>
                For iOS users, tap below to download from the App Store.
              </p>

              <a href="https://apps.apple.com/us/app/flexbike/id6449488641" target="blank">
                <img src={appstore} alt="Download Flexbike on the App Store" />
              </a>
            </>
          )}

          {!isAndroid && !isIOS && (
            <>
              <p className="mx-8 my-8 text-center text-xl leading-12 font-nunito" style={{ color: "#242420" }}>
                It looks like you're not on mobile.
                <br />
                Scan the QR code below on your mobile devices.
              </p>

              <QRCode value={"https://flexbike.app/download"} size={150} bgColor="transparent" />
            </>
          )}

          <p className="mt-16 mb-8 text-center text-s leading-12" style={{ color: "#242420" }}>
            team@flexbike.app
          </p>
        </div>
      </div>
    </>
  );
};

export default DownloadPage;
