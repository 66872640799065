import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import LoadingSpinner from "../components/LoadingSpinner";
import ErrorMessage from "../components/ErrorMessage";
import NoBikesMessage from "../components/NoBikesMessage";
import BikeCard from "../components/BikeCard";
import useCompanyById from "../components/hooks/useGetCompanyById";
import useCompanyBikes from "../components/hooks/useCompanyBikes";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { db } from "../config/firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import Reviews from "../components/Reviews";
import { MdStar, MdSearch } from "react-icons/md";
import { IoShieldCheckmarkOutline } from "react-icons/io5";
import { format } from "date-fns";

const calculateTotalPrice = (
  dailyPrice,
  weeklyPrice,
  monthlyPrice,
  totalDays
) => {
  const fullMonths = Math.floor(totalDays / 30);
  const fullWeeks = Math.floor((totalDays - fullMonths * 30) / 7);
  const fullDays = totalDays - fullMonths * 30 - fullWeeks * 7;

  if (totalDays < 7) {
    return dailyPrice * totalDays;
  } else if (totalDays >= 7 && totalDays < 30) {
    return fullWeeks * weeklyPrice + (totalDays - fullWeeks * 7) * dailyPrice;
  } else if (totalDays >= 30) {
    return (
      fullMonths * monthlyPrice +
      fullWeeks * weeklyPrice +
      fullDays * dailyPrice
    );
  }
  return monthlyPrice;
};

const CompanyCard = () => {
  const { id } = useParams();
  const companyId = id.split("-").pop();
  const {
    company,
    loading: companyLoading,
    error: companyError,
  } = useCompanyById(companyId);
  const {
    companyBikes,
    phoneNumbers,
    loading: bikesLoading,
    error: bikesError,
  } = useCompanyBikes(companyId);

  const [filteredBikes, setFilteredBikes] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [reviewAverage, setReviewAverage] = useState();
  const [reviewCount, setReviewCount] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef(null);

  const [startDate, endDate] = dateRange;

  const fetchAvailableBikes = async (startDate, endDate) => {
    setLoading(true);
    setError(null);

    try {
      // Adjust start and end dates to 08:00:00 (UTC +8)
      const adjustedStartDate = new Date(startDate.setHours(8, 0, 0, 0));
      const adjustedEndDate = new Date(endDate.setHours(8, 0, 0, 0));

      // Fetch bookings with the required statuses
      const statusBookingsQuery = query(
        collection(db, "bookings"),
        where("companyID", "==", companyId),
        where("status", "in", ["pending", "confirmed"])
      );
      const statusBookingsSnapshot = await getDocs(statusBookingsQuery);
      const statusBookings = statusBookingsSnapshot.docs.map((doc) =>
        doc.data()
      );

      // Fetch bookings with marketplaceBooking set to false
      const marketplaceBookingsQuery = query(
        collection(db, "bookings"),
        where("companyID", "==", companyId),
        where("marketplaceBooking", "==", false)
      );
      const marketplaceBookingsSnapshot = await getDocs(
        marketplaceBookingsQuery
      );
      const marketplaceBookings = marketplaceBookingsSnapshot.docs.map((doc) =>
        doc.data()
      );

      // Combine both sets of bookings
      const allBookings = [...statusBookings, ...marketplaceBookings];

      // Filter bikes based on availability
      const availableBikes = companyBikes.filter((bike) => {
        const bikeBookings = allBookings.filter(
          (booking) => booking.bikeID === bike.id
        );

        return !bikeBookings.some((booking) => {
          if (!booking.end) {
            // If booking has no end date, the bike is considered unavailable
            return true;
          }

          const bookingStart = booking.start.toDate();
          const bookingEnd = booking.end.toDate();

          // Adjust booking start and end to 08:00:00 (UTC +8)
          bookingStart.setHours(8, 0, 0, 0);
          bookingEnd.setHours(8, 0, 0, 0);

          const isBookingOverlapping =
            adjustedStartDate <= bookingEnd && adjustedEndDate >= bookingStart;
          return isBookingOverlapping;
        });
      });

      setFilteredBikes(availableBikes);
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    if (startDate && endDate) {
      fetchAvailableBikes(startDate, endDate);
      setIsModalOpen(false); // Close the modal when date range is selected
    }
  }, [startDate, endDate, companyBikes]);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const totalDays =
    startDate && endDate
      ? Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24))
      : 0;

  if (companyLoading || bikesLoading || loading) {
    return <LoadingSpinner />;
  }
  if (companyError || bikesError || error) {
    return <ErrorMessage error={companyError || bikesError || error} />;
  }

  const openBusinessPage = () => {
    window.open("https://flexbike.app/for-business", "_blank");
  };

  return (
    <div className="min-h-screen py-2 bg-white">
      <div className="container max-w-7xl mx-auto px-8 sm:px-6 lg:px-8">
        <Helmet>
          <title>{company?.companyName}</title>
          <meta
            name="description"
            content={`View available bikes from ${company?.companyName}.`}
          />
          <meta property="og:title" content={company?.companyName} />
          <meta
            property="og:description"
            content={`Check out the available bikes from ${company?.companyName}.`}
          />
          <meta
            property="og:image"
            content={company?.imageUrl || "default-image-url"}
          />
        </Helmet>

        <div className="text-3xl font-bold pt-8">{company?.companyName}</div>
        {reviewCount ? (
          <div className="flex flex-row items-center">
            <MdStar size={16} className="text-[#757676]" />
            <p className="text-[#757676]">
              {reviewAverage} ({reviewCount})
            </p>
          </div>
        ) : null}
        <div className="text-[#757676] text-sm mb-4">
          {company?.companyAddress}
        </div>

        <div className="flex flex-wrap">
          <button
            onClick={() => setIsModalOpen(true)}
            className={`font-bold border px-4 py-2 rounded-lg shadow-lg ${
              !startDate || !endDate ? "bg-[#08b7b7]" : ""
            }`}
          >
            {startDate && endDate
              ? `${format(startDate, "dd MMM")} - ${format(endDate, "dd MMM")}`
              : "Enter your dates"}
          </button>
        </div>

        {isModalOpen && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div
              ref={modalRef}
              className="relative bg-white p-6 rounded-lg shadow-lg flex"
            >
              <button
                onClick={() => setIsModalOpen(false)}
                className="absolute top-2 right-2 text-black"
              >
                &times;
              </button>
              <DatePicker
                selected={startDate}
                onChange={(dates) => setDateRange(dates)}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
                minDate={new Date()} // Prevent past dates
                dateFormat="dd MMM"
              />
            </div>
          </div>
        )}

        {!startDate || !endDate ? (
          <div className="text-lg flex-col flex items-center mt-4">
            {/*
            <MdSearch size={48} className="mx-auto text-[#757676]" />
            <p className="text-sm mb-4 text-[#757676]">Enter your dates to see available bikes</p>
        */}
          </div>
        ) : filteredBikes.length === 0 ? (
          <NoBikesMessage />
        ) : (
          <div className="max-w-screen-xl mx-auto py-8">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-3">
              {filteredBikes.map((bike) => {
                const totalPrice = calculateTotalPrice(
                  bike.dailyPrice,
                  bike.weeklyPrice,
                  bike.monthlyPrice,
                  totalDays
                );
                const pricePerDay = (totalPrice / totalDays).toFixed(2);

                // Adjust start and end dates to 08:00:00 (UTC +8)
                const adjustedStartDate = new Date(startDate);
                adjustedStartDate.setHours(8, 0, 0, 0);
                const adjustedEndDate = new Date(endDate);
                adjustedEndDate.setHours(8, 0, 0, 0);

                return (
                  <BikeCard
                    key={bike.id}
                    bike={{
                      ...bike,
                      totalPrice,
                      pricePerDay,
                    }}
                    deliveryAvailable={company?.deliveryAvailable}
                    startDate={adjustedStartDate}
                    endDate={adjustedEndDate}
                  />
                );
              })}
            </div>
          </div>
        )}

        <Reviews
          companyID={companyId}
          setReviewAverage={setReviewAverage}
          setReviewCount={setReviewCount}
        />

        <div className="flex items-center justify-center my-4">
          <div className="w-16 border-t border-gray-300"></div>
          <IoShieldCheckmarkOutline size={18} className="mx-4 text-[#757676]" />
          <div className="w-16 border-t border-gray-300"></div>
        </div>
        <div className="flex flex-col items-center mt-4 mb-4">
          <p className="text-sm text-[#757676] mb-4 text-center">
            {company.companyName} securely accepts online bookings with
          </p>
          <a href="/" className="block lg:scale-100 scale-100">
            <svg
              width="120"
              height="31"
              viewBox="0 0 120 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Flexbike logo</title>
              <path
                d="M31.5254 22.486C31.175 22.1437 31 21.6933 31 21.1354V7.74571C31 7.18777 31.1549 6.75754 31.4646 6.45453C31.7738 6.15203 32.2136 6 32.7844 6H41.1918C41.8107 6 42.2669 6.11945 42.5602 6.35886C42.8536 6.59828 43 6.97265 43 7.48251C43 7.99237 42.8536 8.34347 42.5602 8.58237C42.2669 8.82127 41.8107 8.94123 41.1918 8.94123H34.7644V12.8143H40.7034C41.3223 12.8143 41.7785 12.9337 42.0718 13.1731C42.3652 13.412 42.5116 13.7869 42.5116 14.2968C42.5116 14.8066 42.3652 15.1577 42.0718 15.3966C41.7785 15.6355 41.3223 15.7555 40.7034 15.7555H34.7644V21.1348C34.7644 21.7088 34.5974 22.1628 34.2634 22.4979C33.9293 22.8325 33.4769 23 32.9071 23C32.3373 23 31.8764 22.8288 31.5265 22.486H31.5254Z"
                fill="#757676"
              />
              <path
                d="M44.5695 22.5728C44.1896 22.2878 44 21.8688 44 21.3142V7.68583C44 7.13176 44.1896 6.71223 44.5695 6.42723C44.9487 6.14224 45.4305 6 46.0132 6C46.5958 6 47.0501 6.14224 47.4305 6.42723C47.8098 6.71223 48 7.13176 48 7.68583V21.3142C48 21.8682 47.8098 22.2878 47.4305 22.5728C47.0507 22.8578 46.5781 23 46.0132 23C45.4483 23 44.9493 22.8578 44.5695 22.5728Z"
                fill="#757676"
              />
              <path
                d="M60.6345 20.0287C60.831 20.2583 60.9295 20.553 60.9295 20.9115C60.9295 21.1559 60.8504 21.3817 60.6937 21.5898C60.5365 21.7979 60.3159 21.9739 60.0326 22.1173C59.4976 22.3757 58.8758 22.5875 58.1677 22.7523C57.4596 22.9171 56.8061 23 56.2083 23C54.9489 23 53.8513 22.7775 52.9155 22.3328C51.9793 21.8882 51.2589 21.2532 50.7556 20.4277C50.2517 19.6027 50 18.6302 50 17.5109C50 16.3917 50.244 15.4806 50.7316 14.6477C51.2191 13.8157 51.896 13.1663 52.7614 12.6998C53.6267 12.2337 54.6104 12 55.7121 12C56.8138 12 57.6909 12.2118 58.4857 12.635C59.2801 13.0587 59.8978 13.6612 60.3389 14.4433C60.7795 15.2255 61 16.1408 61 17.1878C61 17.5035 60.9173 17.7442 60.7519 17.909C60.5865 18.0743 60.3542 18.1567 60.0556 18.1567H53.4465C53.5562 19.0036 53.8396 19.6172 54.2965 19.9971C54.7529 20.3774 55.4058 20.5674 56.2558 20.5674C56.7122 20.5674 57.1211 20.5171 57.4831 20.4166C57.845 20.3165 58.2463 20.1796 58.6869 20.0078C58.9069 19.9217 59.1198 19.8462 59.324 19.7815C59.5282 19.7168 59.7094 19.6847 59.8667 19.6847C60.1811 19.6847 60.4369 19.7997 60.634 20.0292L60.6345 20.0287ZM54.1785 14.8302C53.7691 15.225 53.5256 15.7953 53.447 16.5416H58.0028C57.9553 15.7814 57.747 15.2068 57.3774 14.8195C57.0073 14.4321 56.484 14.2385 55.8076 14.2385C55.1312 14.2385 54.588 14.4359 54.1791 14.8307H54.1785V14.8302Z"
                fill="#757676"
              />
              <path
                d="M74 21.5248C74 21.9152 73.8479 22.2591 73.5453 22.5552C73.2422 22.8513 72.8911 23 72.492 23C72.093 23 71.7232 22.8264 71.3831 22.4792L68.5 19.442L65.639 22.4792C65.2989 22.8264 64.9291 23 64.53 23C64.131 23 63.7574 22.8517 63.4547 22.5552C63.1516 22.2591 63 21.9082 63 21.5028C63 21.1269 63.1626 20.7727 63.4878 20.4396L66.4817 17.3804L63.6873 14.5384C63.3617 14.2057 63.1995 13.8515 63.1995 13.4752C63.1995 13.0703 63.3511 12.7235 63.6542 12.434C63.9569 12.145 64.3085 12 64.7075 12C65.1066 12 65.4984 12.1736 65.8385 12.5208L68.4995 15.2976L71.1831 12.5208C71.5231 12.1736 71.9001 12 72.3141 12C72.728 12 73.0642 12.1445 73.3674 12.434C73.6705 12.7235 73.8221 13.0707 73.8221 13.4752C73.8221 13.8515 73.659 14.2057 73.3343 14.5384L70.5178 17.3804L73.5117 20.4396C73.8369 20.7722 73.9995 21.134 73.9995 21.5244H74V21.5248Z"
                fill="#757676"
              />
              <path
                d="M86.405 11.6351C87.2198 12.1246 87.856 12.823 88.3136 13.7304C88.7707 14.6383 89 15.6915 89 16.8913C89 18.091 88.7712 19.1525 88.3136 20.0757C87.856 20.9989 87.216 21.7173 86.3925 22.2304C85.5691 22.7435 84.6334 23 83.5854 23C82.7372 23 81.972 22.83 81.2899 22.491C80.6078 22.1515 80.0838 21.6825 79.718 21.0824V21.2718C79.718 21.7772 79.5518 22.1833 79.2188 22.491C78.8859 22.7988 78.4369 22.9529 77.8714 22.9529C77.3059 22.9529 76.8521 22.7993 76.5116 22.491C76.1705 22.1833 76 21.7772 76 21.2718V7.63395C76 7.14494 76.1786 6.75015 76.5364 6.45009C76.8941 6.15054 77.3636 6 77.9459 6C78.4947 6 78.9355 6.14184 79.2685 6.42602C79.6009 6.71021 79.7676 7.08913 79.7676 7.56226V12.7473C80.1335 12.1635 80.6531 11.7098 81.3271 11.3857C82.0006 11.0626 82.7533 10.9003 83.5849 10.9003C84.649 10.9003 85.5891 11.1451 86.4044 11.6341V11.6351H86.405ZM84.5336 19.389C85.016 18.7971 85.2572 17.965 85.2572 16.8913C85.2572 15.8175 85.016 15.0248 84.5336 14.4647C84.0511 13.9045 83.3691 13.6244 82.4879 13.6244C81.6067 13.6244 80.924 13.9127 80.4416 14.4887C79.9592 15.0653 79.718 15.882 79.718 16.9394C79.718 17.9968 79.9592 18.8376 80.4416 19.4136C80.924 19.9902 81.6056 20.278 82.4879 20.278C83.3701 20.278 84.0511 19.982 84.5336 19.3901V19.389Z"
                fill="#757676"
              />
              <path
                d="M90.5346 8.18296C90.178 7.8501 90 7.39856 90 6.82938C90 6.26021 90.178 5.81341 90.5346 5.48794C90.8912 5.163 91.3796 5 91.9997 5C92.6199 5 93.0886 5.16669 93.4533 5.50007C93.8174 5.83346 94 6.27656 94 6.82938C94 7.38221 93.8215 7.8501 93.4649 8.18296C93.1083 8.51634 92.6199 8.68303 91.9997 8.68303C91.3796 8.68303 90.8912 8.51634 90.5346 8.18296ZM90.7323 22.5611C90.3988 22.2684 90.2324 21.8379 90.2324 21.2682V12.3655C90.2324 11.7964 90.4029 11.3654 90.7439 11.0726C91.0849 10.7799 91.5033 10.6337 91.9997 10.6337C92.4962 10.6337 92.9106 10.7799 93.2441 11.0726C93.5775 11.3654 93.744 11.7964 93.744 12.3655V21.2682C93.744 21.8374 93.577 22.2684 93.2441 22.5611C92.9106 22.8539 92.4957 23 91.9997 23C91.5038 23 91.0658 22.8539 90.7323 22.5611Z"
                fill="#757676"
              />
              <path
                d="M108 21.2882C108 21.7319 107.845 22.1284 107.536 22.477C107.227 22.8257 106.868 23 106.461 23C106.054 23 105.706 22.8334 105.375 22.5007L100.418 17.6743V21.3118C100.418 21.8667 100.255 22.2868 99.9312 22.5722C99.6067 22.8576 99.2028 23 98.7202 23C98.2375 23 97.8111 22.8576 97.4866 22.5722C97.162 22.2868 97 21.8672 97 21.3118V7.68817C97 7.13333 97.162 6.71322 97.4866 6.42783C97.8111 6.14244 98.2223 6 98.7202 6C99.218 6 99.6067 6.14244 99.9312 6.42783C100.256 6.71322 100.418 7.13333 100.418 7.68817V16.2L104.944 11.516C105.276 11.1674 105.646 10.993 106.054 10.993C106.461 10.993 106.846 11.1555 107.163 11.4805C107.48 11.8055 107.638 12.1901 107.638 12.6339C107.638 13.0463 107.464 13.4345 107.117 13.7991L104.107 16.8186L107.479 20.0998C107.826 20.4485 108 20.8449 108 21.2887V21.2882Z"
                fill="#757676"
              />
              <path
                d="M119.634 20.0287C119.831 20.2583 119.93 20.553 119.93 20.9115C119.93 21.1559 119.85 21.3817 119.694 21.5898C119.536 21.7979 119.316 21.9739 119.033 22.1173C118.498 22.3757 117.876 22.5875 117.168 22.7523C116.46 22.9171 115.806 23 115.208 23C113.949 23 112.851 22.7775 111.916 22.3328C110.979 21.8882 110.259 21.2532 109.756 20.4277C109.252 19.6027 109 18.6302 109 17.5109C109 16.3917 109.244 15.4806 109.732 14.6477C110.219 13.8157 110.896 13.1663 111.761 12.6998C112.627 12.2337 113.61 12 114.712 12C115.814 12 116.691 12.2118 117.486 12.635C118.28 13.0587 118.898 13.6612 119.339 14.4433C119.779 15.2255 120 16.1408 120 17.1878C120 17.5035 119.917 17.7442 119.752 17.909C119.586 18.0743 119.354 18.1567 119.056 18.1567H112.446C112.556 19.0036 112.84 19.6172 113.296 19.9971C113.753 20.3774 114.406 20.5674 115.256 20.5674C115.712 20.5674 116.121 20.5171 116.483 20.4166C116.845 20.3165 117.246 20.1796 117.687 20.0078C117.907 19.9217 118.12 19.8462 118.324 19.7815C118.528 19.7168 118.709 19.6847 118.867 19.6847C119.181 19.6847 119.437 19.7997 119.634 20.0292H119.634V20.0287ZM113.179 14.8302C112.769 15.225 112.525 15.7953 112.447 16.5416H117.003C116.955 15.7814 116.746 15.2068 116.377 14.8195C116.007 14.4321 115.484 14.2385 114.808 14.2385C114.131 14.2385 113.588 14.4359 113.179 14.8307H113.179V14.8302Z"
                fill="#757676"
              />
              <path
                d="M10.9832 1.32881e-05C4.9159 0.00968464 0 5.0398 0 11.2432C0 20.8789 6.93684 28.0103 9.79345 30.5391C10.4857 31.1519 11.5121 31.1545 12.2048 30.5416C15.0256 28.0454 21.8374 21.0505 21.9983 11.5848C22.1054 5.26123 17.171 -0.00965806 10.9832 1.32881e-05ZM17.4594 10.9449C16.3374 10.3534 14.6182 9.68663 13.919 10.6863C12.8413 12.2277 14.4295 14.0886 15.7054 15.5424C16.2462 16.1588 16.7164 16.3517 17.0779 16.3695C17.5241 16.3914 17.8409 16.8139 17.7617 17.2634C17.5032 18.7339 16.7911 19.67 16.2711 20.179C15.9763 20.4676 15.5082 20.4422 15.2412 20.1266C11.289 15.4482 6.7466 14.2291 4.70474 13.7852C4.10912 13.656 3.66389 13.1424 3.61509 12.5219C3.58122 12.0953 3.56429 11.6687 3.56429 11.2427C3.56429 7.05857 6.89451 3.64917 10.9907 3.64255H11.0001C12.9802 3.64255 14.8433 4.44578 16.2462 5.90514C17.4096 7.11507 18.1402 8.64213 18.3628 10.2949C18.4315 10.8044 17.9061 11.1806 17.4594 10.9449Z"
                fill="#757676"
              />
            </svg>
          </a>
        </div>
        <p className="text-sm text-[#757676] mb-4 text-center">
          Get your free rental website{" "}
          <span
            className="text-blue-600 cursor-pointer"
            onClick={openBusinessPage}
          >
            here
          </span>
        </p>
      </div>
    </div>
  );
};

export default CompanyCard;
