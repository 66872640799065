import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { auth, db } from "../config/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, setDoc, addDoc, collection } from "firebase/firestore";
import { MdHandshake, MdLocationPin, MdAccessTimeFilled } from "react-icons/md";

const Confirm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentUserId, setCurrentUserId] = useState(null);
  const [riderName, setRiderName] = useState("");
  const [loading, setLoading] = useState(false);
  const [pressed, setPressed] = useState(false);

  const {
    startDate,
    endDate,
    deliveryAddress,
    deliveryTime,
    collectionTime,
    numHelmets,
    requestedDelivery,
    bikeData,
    companyData,
    totalPrice,
    bikeId,
  } = location.state || {};

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUserId(user.uid);
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists()) {
          setRiderName(userDoc.data().name);
        }
      } else {
        navigate("/login");
      }
    });

    // Scroll to top when the component is first rendered
    window.scrollTo(0, 0);

    // Force layout adjustment after initial render
    const timer = setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 100);

    return () => {
      unsubscribe();
      clearTimeout(timer);
    };
  }, [navigate]);

  const options = { weekday: "long", day: "numeric", month: "long" };
  const formattedStartDate = new Date(startDate).toLocaleDateString(
    "en-US",
    options
  );
  const formattedEndDate = new Date(endDate).toLocaleDateString(
    "en-US",
    options
  );

  const calculateNumberOfDays = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const diffTime = Math.abs(endDate - startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const numberOfDays = calculateNumberOfDays(startDate, endDate);

  const formatPrice = (price) => {
    const numberPrice = Number(price);
    const parts = numberPrice.toFixed(0).split(".");
    const formatted = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return formatted;
  };

  if (!currentUserId) {
    return null; // Or a loading spinner
  }

  const newBooking = {
    riderID: currentUserId,
    start: startDate,
    end: endDate,
    companyID: bikeData.companyID,
    bikeID: bikeId,
    requestedDelivery: requestedDelivery,
    deliveryAddress: deliveryAddress || null,
    deliveryTime: deliveryTime || null,
    collectionTime: collectionTime || null,
    numHelmets: numHelmets || 2,
    marketplaceBooking: true,
    price: totalPrice,
    riderName: riderName,
    deliveryCharge: 0,
    deposit: bikeData.deposit,
    monthlySub: false,
    subActive: false,
    bookingMade: new Date(),
    status: "pending",
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await addDoc(collection(db, "bookings"), newBooking);
      navigate("/request-sent", {
        state: { from: "/book-now", companyData, startDate, bikeData },
        replace: true,
      });
    } catch (error) {
      console.error("Error saving booking: ", error);
    }
    setLoading(false);
  };

  const openTerms = () => {
    window.open("https://flexbike.app/terms", "_blank");
  };

  return (
    <div className="min-h-screen bg-white flex">
      <div className="flex-grow max-w-7xl mx-auto flex flex-col lg:flex-row">
        {/* Selection Area */}
        <div className="lg:w-2/3 p-8 pb-32 lg:pb-8">
          <div className="flex flex-row">
            <p>Delivery &gt;&nbsp;</p>
            <p>Confirm</p>
          </div>
          <h1 className="text-2xl font-bold mb-6">Review and confirm</h1>
          <div className="rounded-lg pb-4 lg:hidden">
            <p className="font-bold">
              {bikeData.name} {bikeData.displacement}cc {bikeData.year}
            </p>
            <div className="lg:hidden text-sm text-[#757676]">
              {companyData && (
                <>
                  <p>{companyData.companyName}</p>
                </>
              )}
              <p className="block text-sm text-[#757676]">
                {formattedStartDate} - {formattedEndDate}
              </p>
            </div>
            <div className="my-4 border-t border-gray-300"></div>

            <p className="block text-sm mb-1">
              Rp{formatPrice(totalPrice / numberOfDays)} x {numberOfDays} days
            </p>
            <p className="block text-[#757676] font-bold">
              Total: Rp{formatPrice(totalPrice)}
            </p>
            <p className="block text-[#708238] font-bold text-lg">
              Pay now: Rp0
            </p>
            <p>
              Pay on {requestedDelivery ? "delivery" : "collection"}: Rp
              {formatPrice(totalPrice)}
            </p>
            <p className="block text-[#757676] text-sm">
              Refundable security deposit: Rp
              {formatPrice(bikeData.deposit)}
            </p>
          </div>
          <h2 className="text-xl font-semibold mb-4">Payment method</h2>
          <div className="border border-gray-300 rounded-lg p-4 flex items-center">
            <MdHandshake
              size={18}
              className="text-[#757676] mr-2 flex-shrink-0 mr-4"
            />
            <p>Pay on {requestedDelivery ? "delivery" : "collection"}</p>
          </div>
          {requestedDelivery ? (
            <div className="">
              <h2 className="text-xl font-semibold mb-4 mt-6">Delivery</h2>
              <div className="border border-gray-300 rounded-lg p-4">
                <div className="flex items-center mb-4">
                  <MdLocationPin
                    size={18}
                    className="text-[#757676] mr-2 flex-shrink-0 mr-4"
                  />
                  <p className="block">Delivering to {deliveryAddress}</p>
                </div>
                <div className="flex items-center">
                  <MdAccessTimeFilled
                    size={18}
                    className="text-[#757676] mr-2 flex-shrink-0 mr-4"
                  />
                  <p className="block">Delivery time: {deliveryTime}</p>
                </div>
              </div>
            </div>
          ) : (
            <div className="">
              <h2 className="text-xl font-semibold mb-4 mt-6">Collection</h2>
              <div className="border border-gray-300 rounded-lg p-4">
                <div className="flex items-center mb-4">
                  <MdLocationPin
                    size={18}
                    className="text-[#757676] mr-2 flex-shrink-0 mr-4"
                  />
                  <p className="block">
                    Collect from: {companyData.companyAddress}
                  </p>
                </div>
                <div className="flex items-center">
                  <MdAccessTimeFilled
                    size={18}
                    className="text-[#757676] mr-2 flex-shrink-0 mr-4"
                  />
                  <p className="block">Collection time: {collectionTime}</p>
                </div>
              </div>
            </div>
          )}

          <p className="text-xs text-[#757676] mt-8">
            By pressing 'Confirm' you agree to our
            <span
              className="text-blue-600 cursor-pointer ml-1"
              onClick={openTerms}
            >
              Terms & Conditions
            </span>
          </p>

          <div className="h-48" />
        </div>

        {/* Details Area */}
        <div className="lg:w-1/3 p-6 rounded-lg lg:ml-6 sticky bottom-0 lg:static w-full lg:w-auto mt-auto lg:mt-6 lg:mb-6 flex flex-col justify-between bg-[#F6F5F1]">
          <div>
            {bikeData && (
              <div>
                <p className="font-bold hidden lg:block">
                  {bikeData.name} {bikeData.displacement}cc {bikeData.year}
                </p>
                <div className="hidden lg:block">
                  {companyData && (
                    <p className="text-sm text-[#757676]">
                      {companyData.companyName}
                    </p>
                  )}
                  <p className="block text-sm text-[#757676]">
                    {formattedStartDate} - {formattedEndDate}
                  </p>
                  <div className="my-4 border-t border-gray-300"></div>
                  <p className="block text-sm mb-1">
                    Rp{formatPrice(totalPrice / numberOfDays)} x {numberOfDays}{" "}
                    days
                  </p>
                  <p className="block font-bold">
                    Total: Rp{formatPrice(totalPrice)}
                  </p>
                  <p className="block text-[#708238] font-bold text-xl">
                    Pay now: Rp0
                  </p>
                  <p className="block text-[#757676]">
                    Pay on {requestedDelivery ? "delivery" : "collection"}: Rp
                    {formatPrice(totalPrice)}
                  </p>
                  <p className="block text-[#757676] text-sm">
                    Refundable security deposit: Rp
                    {formatPrice(bikeData.deposit)}
                  </p>
                </div>
              </div>
            )}
            <div>
              <div className="flex justify-between items-center lg:hidden">
                <div>
                  <p className="block font-bold">Rp{formatPrice(totalPrice)}</p>
                  <p className="block text-sm mb-1 text-[#757676]">
                    {bikeData.name} |{" "}
                    {requestedDelivery ? "Delivering on" : "Collecting on"}{" "}
                    {formattedStartDate}
                  </p>
                </div>
                <button
                  type="button"
                  className="bg-[#08b7b7] text-black py-2 px-4 rounded-lg hover:bg-[#e6cc3d] transition duration-300 shadow-md font-bold"
                  onClick={handleSubmit}
                  disabled={loading || pressed}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>

          <div className="mt-0 text-center">
            <button
              type="button"
              className="hidden lg:block w-full bg-[#08b7b7] text-black py-2 px-4 rounded hover:bg-[#e6cc3d] transition duration-300 mt-4 shadow-md mb-4 font-bold"
              onClick={handleSubmit}
              disabled={loading || pressed}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirm;
